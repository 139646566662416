<template>
  <div class="content">
    <button v-if="isCart" class="allclearbn" @click="clearAllCart">
      カートの内容を削除する
    </button>

    <plugin_cartFlow flow="6" />

    <div class="steps">
      <div class="steptitle">ステップ6：お支払い方法の選択</div>
      <div class="stepcontent" v-if="loaded">
        <div class="paymentdetail">
          <h2>お支払い方法</h2>

          <div v-if="updatemode == 'same'">
            変更無し（{{ record.fields.payment_type.textvalue_ordered }}）
          </div>
          <div v-else>
            <select
              v-model="record.fields.payment_type.value"
              @change="errmsg = ''"
            >
              <option value="">選択してください</option>
              <option
                v-for="(payment, p) in computed.payments"
                :key="p"
                :value="payment.ID"
              >
                {{ payment.page_title }}
              </option>
            </select>
            <div
              v-if="
                selectedPayment &&
                selectedPayment.fields.type.value == 'epsilon_card'
              "
            >
              <div class="cardContainer">
                <h3>カード情報の入力</h3>

                <div v-if="Object.keys(cardToken).length">
                  カード番号：{{ cardToken.maskedCardNo }} <br /><span
                    class="cardDelete"
                    @click="cardDelete"
                    >他のカードを使用する</span
                  >
                </div>
                <div v-else>
                  <p class="status" :class="{ error: errmsg_card != '' }">
                    {{ cardstatus }}{{ brand }}
                  </p>
                  <dl>
                    <dt>カード番号</dt>
                    <dd>
                      <input
                        placeholder="例:1234567890123456"
                        :disabled="cardChecking"
                        name="cardno"
                        type="text"
                        v-model="cardnumber"
                      />
                    </dd>
                  </dl>
                  <dl>
                    <dt>有効期限</dt>
                    <dd>
                      <select name="cardexpire_month" v-model="expire_m">
                        <option v-for="i in 12" :key="i" :value="nPad(i, 2)">
                          {{ nPad(i, 2) }}
                        </option></select
                      >月
                      <select name="cardexpire_year" v-model="expire_y">
                        <option
                          v-for="i in 20"
                          :key="i"
                          :value="i - 1 + currentfullyear"
                        >
                          {{ i - 1 + currentfullyear }}
                        </option></select
                      >年
                    </dd>
                  </dl>
                  <dl>
                    <dt>カード氏名</dt>
                    <dd>
                      <input
                        placeholder="例:TAROU SANTA"
                        name="holdername"
                        type="text"
                        v-model="holdername"
                      />
                    </dd>
                  </dl>
                  <dl>
                    <dt>セキュリティーキー</dt>
                    <dd>
                      <input
                        placeholder="例:012"
                        name="securitycode"
                        type="text"
                        v-model="securitycode"
                        size="3"
                         autocomplete="off"
                      />
                    </dd>
                  </dl>
                </div>
              </div>
            </div>

            <div
              class="paymentinfo"
              v-if="selectedPayment && selectedPayment.fields.detail.value"
              v-html="htmlformat(selectedPayment.fields.detail.value)"
            ></div>
          </div>
          <div class="error" v-if="errmsg">{{ errmsg }}</div>
        </div>

        <div class="itemcompute">
          <dl>
            <dt>商品金額合計</dt>
            <dd>
              {{ computed.price_taxation }}円<span class="tax">{{
                computed.taxview
              }}</span>
            </dd>
          </dl>
          <dl>
            <dt>合計配送料</dt>
            <dd>{{ computed.delivercost }}円</dd>
          </dl>
          <dl v-if="computed.payment_customdeliver != 0">
            <dt>カスタム配送料</dt>
            <dd>{{ computed.payment_customdeliver }}円</dd>
          </dl>
          <dl v-if="computed.payment_fee != 0">
            <dt>手数料</dt>
            <dd>{{ computed.payment_fee }}円</dd>
          </dl>

          <dl v-if="computed.campains && computed.campains.length">
            <dt>適用されたキャンペーン</dt>
            <dd v-for="(campain, c1) in computed.campains" :key="c1">
              <div class="camp_title">{{ campain.title }}</div>
              <div class="camp_desc">{{ campain.description }}</div>
            </dd>
          </dl>

          <dl v-if="computed.discount != '0'">
            <dt>割引</dt>
            <dd>-{{ computed.discount }}円</dd>
          </dl>
          <dl>
            <dt v-if="computed.taxtype == 'include'">(うち消費税)</dt>
            <dt v-if="computed.taxtype == 'exclude'">消費税</dt>
            <dd>{{ computed.tax }}円</dd>
          </dl>
          <dl class="amount">
            <dt>お支払い/合計金額</dt>
            <dd>
              {{ computed.total_amount }}円<span class="tax">{{
                computed.taxview
              }}</span>
            </dd>
          </dl>
        </div>
      </div>
    </div>

    <div class="footbns">
      <a class="bn_back" @click.prevent="back">前へもどる</a>
      <a class="bn_next" v-if="!cardChecking" @click.prevent="submit"
        >最終確認画面へ</a
      >
      <a class="bn_back" v-else>(少々お待ちください...)</a>
    </div>


    <dialogalert
      v-model="visible_error"
      :title="error_title"
      :info="error_info"
      button_ok="OK"
    />

    <fullloader :loading="fullloading" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      computed: {},
      prepared: false,
      cardChecking: false,
      cardnumber: "",
      expire_y: "",
      expire_m: "",
      securitycode: "",
      holdername: "",
      brand: "",
      cardToken: {},
      errmsg: "",
      errmsg_card: "",
      updatemode: "",
    };
  },
  created: function () {
    this.cardChecking=false;
    this.fullloading = true;
    this.$parent.middle = 2;
    this.loaded_countmax = 1;
    this.get_totalamount().then((data) => {
      console.log(data);
      this.computed = data;
      if (this.computed.taxtype == "include") this.computed.taxview = "(税込)";
      if (this.computed.taxtype == "exclude") this.computed.taxview = "(税別)";

      this.loaded_countup();

      //注文の編集モードの場合は、この時点で価格が変わらなければ表示専用とする
      if (this.ID) {
        console.log("注文の編集モードの場合");
        let price_ordered = JSON.parse(
          this.record.fields.prices.value
        ).total_amount;
        console.log("前回", price_ordered, "今回", data.total_amount);
        if (price_ordered == data.total_amount) {
          this.updatemode = "same";
        }
      }
    });

    var cardToken = this.getSession("cardToken");
    if (cardToken) this.cardToken = cardToken;
  },
  computed: {
    currentfullyear: function () {
      return new Date().getFullYear();
    },
    currentyear: function () {
      return new Date().getFullYear() - 2000;
    },
    label: function () {
      return this.$route.params.label;
    },
    ID: function () {
      return this.$route.params.ID;
    },
    cardstatus: function () {
      if (this.errmsg_card) return this.errmsg_card;
      if (this.cardChecking) return "お待ちください..";
      if (this.prepared) return "カードチェックOK";
      return "カード情報を入力してください";
    },
    selectedPayment: function () {
      console.log("this.computed.payments");
      console.log(this.computed.payments);
      if (!this.record.fields.payment_type.value) return false;
      for (let i = 0; i < this.computed.payments.length; i++) {
        //if(!record.fields.payment_type.value)return
        if (
          this.computed.payments[i].ID == this.record.fields.payment_type.value
        ) {
          return this.computed.payments[i];
        }
        //return false;
      }
      return false;
    },
  },
  methods: {
    scrollHome: function () {
      let htop = document.getElementById("header").offsetHeight;
      console.log("header", htop);
      window.scrollTo(0, htop);
      //window.scrollTo(0, 2000);
      //window.scrollTo(0, 0);
    },
    nPad(num, length) {
      return ("0000000000" + num).slice(-length);
    },
    submit: function () {
      if (!this.selectedPayment) {
        this.errmsg = "お支払い方法が選択されていません";
        
          this.error_title= "お支払い方法を選択してください";
          this.error_info = "お支払い方法を選択してください。";
          this.visible_error = true;
          
        return;
      }
      if (
        Object.keys(this.cardToken).length ||
        this.selectedPayment.fields.type.value != "epsilon_card"
      ) {
        if (this.ID) {
          this.$router.push(
            "/item/" + this.label + "/confirm/update/" + this.ID
          );
          this.scrollHome();
        } else {
          this.$router.push("/item/" + this.label + "/confirm");
          this.scrollHome();
        }
      } else {
        this.getCardToken();
      }
      //this.$router.push("/item/" + this.label + "/confirm");
    },
    gotTokenResponse: function (response) {
      console.log("response", response);

      if (response.resultCode == "000") {
        this.errmsg_card = "";
        this.setSession("cardToken", response.tokenObject);
        this.cardToken = response.tokenObject;
        this.prepared = true;
        this.brand = this.checkLength();

        if (this.ID) {
          this.$router.push(
            "/item/" + this.label + "/confirm/update/" + this.ID
          );
          this.scrollHome();
        } else {
          this.$router.push("/item/" + this.label + "/confirm");
          this.scrollHome();
        }

        console.log("err2", response.resultCode);
      } else {
        console.log("err 情報の入力に問題があります。再度入力内容をお確かめください。");
        this.errmsg_card = "情報の入力に問題があります。再度入力内容をお確かめください。";
        console.log("err????????????????2");
        
          this.error_title= "入力内容をお確かめください。";
        console.log("err????????????????3");
          this.error_info = "情報の入力に問題があります。再度入力内容をお確かめください。";
        console.log("err????????????????4");
          this.visible_error = true;
        console.log("err????????????????5");

      }
        console.log("err????????????????6");
      this.$nextTick(function(){
        console.log("nextTick cardChecking=false;a");
          this.cardChecking = false;
          console.log("err???????????????? nextTick 7");
          console.log("cardChecking", this.cardChecking);
      }.bind(this))
    },
    cardWatch: function (response) {
      if (this.cardChk(this.cardnumber)) {
        console.log("かーどOK ");
        this.getCardToken();
      } else {
        console.log("かーど中");
      }
    },
    cardDelete: function () {
      this.setSession("cardToken", {});
      this.cardToken = "";
    },
    getCardToken: function () {
      console.log("cardChecking=true Aa");
      this.cardChecking = true;
      console.log("cardChecking=true;");
      var cardObj = {};
      cardObj.cardno = this.cardnumber;
      if (this.expire_y.length == 2) this.expire_y = this.expire_y - 0 + 2000;
      cardObj.expire = this.expire_y + "" + this.expire_m;
      cardObj.securitycode = this.securitycode;
      cardObj.holdername = this.holdername;
      console.log("cardObj", cardObj);
      let that = this;

      if (typeof window.execTrade == "undefined")
        window.execTrade = function (response) {
          this.gotTokenResponse(response);
        }.bind(this);

      console.log(window.EpsilonToken);
      window.EpsilonToken.init("60236570");
      window.EpsilonToken.getToken(cardObj, "execTrade");
    },

    checksum(number) {
      const temp = typeof number === "string" ? number : String(number);
      const n = temp
        .split("") // 1文字ずつ分割し配列に
        .map((a) => Number(a)) // 配列の各要素を文字列型→数値型に変換
        .reverse(); // 配列を逆順にする
      let total = 0;

      for (let i = 0; i < n.length; i++) {
        if ((i + 1) % 2 === 0) {
          const value = n[i] * 2;
          total += value > 9 ? value - 9 : value;
        } else {
          total += n[i];
        }
      }

      return total % 10 === 0;
    },

    checkLength(number) {
      const n = typeof number === "string" ? number : String(number);
      const between = (value, min, max) =>
        min <= Number(value) && Number(value) <= max;

      // 13〜16桁の整数で無いならfalseを返して終了
      if (!n.match(/^[0-9]{13,16}$/)) {
        return false;
      }

      //--------------------------------------
      // カード会社を判定しつつチェック
      //--------------------------------------
      // VISA
      // (13桁はすでに存在しない説があるため後ろに持ってくる)
      if (n.substr(0, 1) === "4" && (n.length === 16 || n.length === 13)) {
        return "visa";
      }
      // MASTER Card
      else if (
        (n.match(/^5[1-5]/) || between(n.substr(0, 6), 222100, 272099)) &&
        n.length === 16
      ) {
        return "mastercard";
      }
      // JCB
      else if (between(n.substr(0, 4), 3528, 3589) && n.length === 16) {
        return "jcb";
      }
      // American Express
      else if (n.match(/^3[47]/) && n.length === 15) {
        return "americanexpress";
      }
      // Diners Club
      else if (
        (between(n.substr(0, 6), 300000, 303574) ||
          n.substr(0, 4) === "3095" ||
          n.match(/^3[689]/)) &&
        n.length === 14
      ) {
        return "dinersclub";
      }

      return false;
    },

    cardChk(number) {
      if (this.checksum(number) && this.checkLength(number) !== false) {
        console.log("valid");
        return true;
      } else {
        console.log("invalid");
        return false;
      }
    },
    back: function () {
      this.scrollToTop();
      if (this.ID) {
        this.$router.push("/item/" + this.label + "/deliver/update/" + this.ID);
      } else {
        this.$router.push("/item/" + this.label + "/deliver");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  color: #000;
}
.stepcontent {
  padding: 1.5em;
}
.paymentdetail {
  border-radius: 0.7em;
  border: solid 1px #ccc;
  background: rgb(243, 235, 229);
  padding: 1.5em;
  h2 {
    font-weight: normal;
  }
}
.itemcompute {
  margin-top: 1.5em;
  text-align: right;
  dt {
    font-size: 0.9em;
    border-bottom: solid 1px #ccc;
  }
  dd {
    margin-bottom: 1em;
  }
  .amount {
    dt {
      font-size: 1.1em;
    }
    dd {
      font-size: 1.6em;
    }
  }
}
.paymentinfo,
.cardContainer {
  margin-top: 1em;
  border: solid 1px #ccc;
  background: #ffffff;
  padding: 1.5em;
  h3 {
  }
  dt {
    margin-top: 0.7em;
    font-size: 0.85em;
    font-weight: bold;
    color: rgb(65, 65, 65);
  }
  .status {
    margin-bottom: 0.6em;
    background: rgb(246, 246, 246);
    padding: 1em;
  }
  .cardDelete {
    border: solid 1px #ccc;
    display: inline-block;
    padding: 0.1em 0.6em;
    border-radius: 0.4em;
    cursor: pointer;
    &:hover {
      background: rgb(238, 238, 238);
    }
  }
}

.camp_title {
  font-size: 0.8em;
  color: #383838;
  font-weight: bold;
  padding: 0.7em;
}
.camp_desc {
  background: rgb(251, 251, 251);
  border-radius: 0.6em;
  padding: 0.7em;
  font-size: 0.7em;
}
.error {
  background: rgb(241, 248, 234) !important;
  padding: 0.5em;
  border-radius: 0.3em;
  margin-top: 0.8em;
}
</style>